// const routes = [{
//   path: '/',
//   component: () => import('@/views/layout/Index'),
//   redirect: '/order-statistics',
//   children: [{
//     path: '/order-statistics',
//     component: () => import('@/views/index/statistics/Index'),
//     meta: {
//       title: '首页',
//       icon: 'el-icon-menu'
//     }
//   }, {
//     path: '/system',
//     component: () => import('@/views/layout/BrigeRouterView'),
//     meta: {
//       title: '系统设置',
//       icon: 'el-icon-setting'
//     },
//     children: [{
//       path: '/menus',
//       component: () => import('@/views/system/menus/index/Index'),
//       meta: {
//         title: '菜单管理'
//       }
//     }, {
//       path: '/add-menus',
//       component: () => import('@/views/system/menus/add/Index'),
//       meta: {
//         title: '添加菜单',
//         hidden: true
//       }
//     }, {
//       path: '/edit-menus',
//       component: () => import('@/views/system/menus/add/Index'),
//       meta: {
//         title: '编辑菜单',
//         hidden: true
//       }
//     }, {
//       path: '/roles',
//       component: () => import('@/views/system/roles/index/Index'),
//       meta: {
//         title: '角色管理'
//       }
//     }, {
//       path: '/add-roles',
//       component: () => import('@/views/system/roles/add/Index'),
//       meta: {
//         title: '添加角色',
//         hidden: true
//       }
//     }, {
//       path: '/edit-roles',
//       component: () => import('@/views/system/roles/add/Index'),
//       meta: {
//         title: '编辑角色',
//         hidden: true
//       }
//     }, {
//       path: '/admin',
//       component: () => import('@/views/system/admin/index/Index'),
//       meta: {
//         title: '管理员管理'
//       }
//     }, {
//       path: '/add-admin',
//       component: () => import('@/views/system/admin/add/Index'),
//       meta: {
//         title: '添加管理员',
//         hidden: true
//       }
//     }, {
//       path: '/edit-admin',
//       component: () => import('@/views/system/admin/add/Index'),
//       meta: {
//         title: '编辑管理员',
//         hidden: true
//       }
//     }]
//   }]
// }, {
//   path: '/login',
//   component: () => import('@/views/Login')
// }, {
//   path: '/404',
//   component: () => import('@/views/NotFound')
// }, {
//   path: '*',
//   redirect: '/404'
// }]
const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView,
    component:()=>import ('@/components/HelloWorld.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }

]
export default routes
