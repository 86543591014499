<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="" /> -->
    <router-view/>

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  mounted() {
    window.onload = function () {
      console.log(window.sessionStorage["ISlogin"]);
      if (!window.sessionStorage["ISlogin"]) {
        // 关闭浏览器
        window.localStorage.clear();
      } else {
        // 刷新
      }
    };
    window.onunload = function () {
      window.sessionStorage["ISlogin"] = true;
    };
    window.onbeforeunload = function () {
      window.sessionStorage["ISlogin"] = false;
    };
  },
  destroyed() {
    window.localStorage.clear()
  }
}
</script>

<style>

</style>
