import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

// 白名单路由path
const whitePaths = ['/', '/about']
// 全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 1、判断是不是登陆权限的页面
  // 1、1如果不需要登陆，直接放行
  // 1、2如果需要登录检测
  // 1、2、1 检测本地存储中有无token
  if (whitePaths.includes(to.path)) {
    next()
  } else {
    // 从本地存储中提起user信息

    next('/')
  }
})

export default router
