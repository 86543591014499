import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
// import './util/rem'
import './assets/iconfont/iconfont.css'
import './assets/css/base.css'
import './assets/css/public.css'
// import './assets/css/duan.scss'
// import 'lib-flexible/flexible.js'
import axios from 'axios'
// import VueAxios from 'vue-axios'


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)
// Vue.config.productionTip = false

Vue.prototype.axios = axios;
axios.defaults.baseURL = '/api'
Vue.prototype.Bus = new Vue()
// Vue.use(VueAxios)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
